import { Suspense, lazy } from "react";

const DynamicBookingLogin = lazy(() => import("./index.component"));

const BookingLoginApp = () => {
  return (
    <Suspense>
      <DynamicBookingLogin />
    </Suspense>
  );
};

export default BookingLoginApp;

import { Suspense, lazy } from "react";

const DynamicBookingEngineApp = lazy(() => import("./index.component"));

const BookingBarApp = () => {
  return (
    <Suspense>
      <DynamicBookingEngineApp />
    </Suspense>
  );
};

export default BookingBarApp;

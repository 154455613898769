import { Suspense, lazy } from "react";

const DynamicBookingBar = lazy(() => import("./index.component"));

const BookingBar = () => {
  return (
    <Suspense>
      <DynamicBookingBar />
    </Suspense>
  );
};

export default BookingBar;
